import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import Wildcard from "../../../../services/wildcard";
import { masking, unmasking } from "../../../../services/masking";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AxiosWrapper from "../../../../services/axios";
import { useEffect } from "react";
import moment from "moment";
import marketing from "../../../../services/marketing";
import Parser from 'react-html-parser';
import PixelsService from '../../../../services/pixels';
import AudioEyeSupport from "../../../../services/audioEyeSupport";
import AdobeScript from "../../../../services/adobeScript";
import FeaturesService from "../../../../services/features";

function Landing() {
  const ada = new AudioEyeSupport();
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const text = useStoreState((state) => state.dmText.landing);
  const utms = new marketing();
  const features = new FeaturesService();
  const setInfoStore = useStoreActions(
    (actions) => actions.applicantModel.setApplicant
  );
  const setOnlineCreditProtectionUrl = useStoreActions(
    (actions) => actions.siteSettingsModel.setCreditProtectionUrl
  );
  const setMainPath = useStoreActions(
    (actions) => actions.siteSettingsModel.setMainPath
  );
  const setMainSettings = useStoreActions(
    (actions) => actions.siteSettingsModel.setMainSettings
  );
  const adobeAds = new AdobeScript()
  const storeApplicantData = useStoreActions(
    (actions) => actions.applicantModel.storeApplicantData
  );
  const history = useHistory();
  const wildcard = new Wildcard();
  const axios = new AxiosWrapper();
  const prequal = ["Surge", "Reflex"];
  let [info, setInfo] = useState({
    reservationNumber: "",
    ssn: "",
    productName: Setting.brandSettings.ProductName,
    campaignId: axios.findParam("campaignId") || "",
    useLast4SSN : false
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors
  } = useForm({ mode: "onSubmit" });
  const [isLoading, setLoading] = useState(false);
  const [failedAttemptsCounter, setFailedAttemptsCounter] = useState(0);
  const setdisplayTerms = useStoreActions((actions) => actions.setDisplayPrequalTerms);
  const PixelService = new PixelsService();
  const setInitPixels = useStoreActions(actions => actions.setInitPixels);
  const initPixels = useStoreState(state => state.initPixels);
  const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
  const setUseOwnBanner = useStoreActions(actions => actions.siteSettingsModel.setUseOwnBanner);

  useEffect(() => {
    if(!initPixels){
      setUseOwnBanner(true)
      utms.mapper();
      utms.trackEvent(7, "DM", "", Setting.productSettings.ProductId, "Flow");
      ada.setup();
      adobeAds.setup()
      PixelService.initialize().pageView("/landing").event("google", "tracking", "landing");
      
      setInitPixels(true)
    }
    getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (axios.findParam("hash")) {
      axios.get("get-dm-reservation-by-hash/" + axios.findParam("hash")).then((response) => {
          if (response) {
            if (response !== null) {
              setValue("reservationNumber",response)
              setInfo({ ...info, reservationNumber: response });
            }
          }
          return;
        })
        .catch((err) => {
          return;
        });
    }
    if(axios.findParam("code")){
      axios.get("decrypt-hash/" +decodeURI(axios.findParam("code"))).then((response) => {
        console.log(response)
        if (response) {
          if (response !== null) {
            setValue("reservationNumber",response)
            setInfo({ ...info, reservationNumber: response });
          }
        }
        return;
      })
      .catch((err) => {
        return;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (data) => {

    setLoading(true);

    axios
      .post("/validate-dm-reservation", info, true)
      .then((response) => {

        if ( typeof response.Success === undefined || response.Success === false || String(response.Payload.ReservationCode || 0) !== info.reservationNumber ) {
          if(response.ErrorItem.ErrorCode === 404){
            setFailedAttemptsCounter(failedAttemptsCounter + 1);
           
            setError("notfound", {type : "custom", message : response.ErrorItem.Message || "Invalid data entered"});
            setLoading(false);
            return;
          }else{
            setFailedAttemptsCounter(failedAttemptsCounter + 1);
            setError("reservationNumber", {type : "custom", message : response.ErrorItem.Message || "Invalid Reservation Number"});
            setLoading(false);
            return;
          }
          
        }
        response = response.Payload;
        response.dob = moment(response.BirthDate, "YYYYMMDD", true).format(
          "YYYY-MM-DD"
        );
        response.SSN = info.ssn;
        response.PrimaryPhone = response.MobileNo || "";
        response.PrimaryPhoneSms = response.MobileNo || "";
        response.SecondaryPhone = response.HomeNo || "";
        response.SecondaryPhoneSms = response.HomeNo || "";
        storeApplicantData(response);
        setInfoStore({ index: "step", value: 2 });
        window.scrollTo(0, 0);
        setdisplayTerms(true)

const reservationCode = parseInt(response.ReservationCode.substring(1), 10);
const dmResRanges = [
  [41282317994, 41308929276], // June
  [41308929284, 41331474845], // July
  [41331474852, 41338573870], // August
  [66641110200,66641110200]
];

let creditProtectionPrice = "1.39";

for (const [start, end] of dmResRanges) {
  if (reservationCode >= start && reservationCode <= end) {
    creditProtectionPrice = "0.99"; 
    setInfoStore({ index: "CreditProtectionPrice", value: creditProtectionPrice });
    setOnlineCreditProtectionUrl();
    break;
  }
}
        setMainSettings({ProductCode : response.ProductCode || Setting.mainSettings.ProductCode})
        setMainPath('/terms/')
        history.push("/terms" + window.location.search);
        setLoading(false);
        return;
      })
      .catch((err) => {
        setError("reservationNumber");
        setLoading(false);
        return;
      });
  };

  const changeReservationNumber = (newValue) => {
    clearErrors("notfound");
    const handlePaste = (inputValue) => {
      return inputValue.replace(/[^D\d]/g, ''); 
    };

    const formattedValue = newValue.startsWith('D') ? newValue : 'D' + newValue;
  
    if (formattedValue === 'D') {
      setInfo({
        ...info,
        reservationNumber: formattedValue,
      });
    } else if (formattedValue !== 'D') {
      setInfo({
        ...info,
        reservationNumber: handlePaste(formattedValue),
      });
    }
  };

  return (
    <>
      <div
        className="row"
        id="header-text-container"
        style={{ backgroundColor: Setting.brandSettings.Primary }}
      >
        <div className="col-12 text-center">
          <h1 id="offer-header-text" className="mt-2 mb-2">
            {wildcard.update().proccess(text.block1.title)}
          </h1>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <form onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col-lg-5 col-xs-6">
              <div className="form-group">
                <label
                  className="text-center w-100"
                  htmlFor="reservationNumber"
                >
                  {text.block1.reservationNumber}
                </label>
                <div className="input-container">
                  <input
                    value={masking(
                      info.reservationNumber.replace("D", ""),
                      "DXXXXXXXXXXX"
                    )}
                    onInput={(e) => {
                      const value = "D" + e.target.value.replace(/[^0-9]/g, "").slice(0, 11); 
                      changeReservationNumber(value);
                    }}
                    type="text"
                    className="form-control text-center"
                    placeholder="DXXXXXXXXXX"
                    {...register("reservationNumber", {required : "Please enter your Reservation Number", minLength : { value : 12, message : "Invalid Reservation Number"}})}
                  />
                </div>
                {errors.reservationNumber ? (
                  <div className="error-message text-center">
                    {errors.reservationNumber.message}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group">
                <label className="text-center w-100" htmlFor="ssn">
                  {text.block1.securityNumber}
                </label>
                <div className="input-container">
                  <input
                    value={masking(info.ssn, "XXX-XX-XXXX")}
                    onInput={(e) => {
                      clearErrors("notfound")
                      const regExp = /^[0-9]*$/;
                      // We need to check only numbers without dashes, the e.target.value is the masked input.
                      const valueWithoutDashes = e.target.value.replaceAll("-", "");
                      if (regExp.test(valueWithoutDashes)) {
                        setInfo({
                          ...info,
                          ssn: unmasking(e, "XXX-XX-XXXX", info.ssn),
                        });
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault(); 
                      const pastedText = e.clipboardData.getData("text/plain");
                      const onlyNumbers = pastedText.replace(/[^\d]/g, ""); 
                      const currentCursorPosition = e.target.selectionStart;
                      const newValue = e.target.value.substring(0, e.target.selectionStart) + onlyNumbers + e.target.value.substring(e.target.selectionEnd);
                      e.target.value = newValue;
                      e.target.selectionStart = e.target.selectionEnd = currentCursorPosition + onlyNumbers.length;
                      setInfo({...info, ssn: unmasking(e, "XXX-XX-XXXX", info.ssn)});
                    }}
                    type="text"
                    maxLength={11}
                    className="form-control text-center"
                    placeholder="XXX-XX-XXXX"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        const regExp = new RegExp("([0-9]+$|^$)");
                        // We need to check only numbers without dashes, the e.target.value is the masked input.
                        const valueWithoutDashes = event.target.value.replaceAll("-", "");
                        if (regExp.test(valueWithoutDashes)) {
                            setInfo({
                            ...info,
                            ssn: unmasking(event, "XXX-XX-XXXX", info.ssn),
                            });
                            setValue("ssn",event.target.value )
                        }
                        handleSubmit(submit)();
                      }
                    }}
                    {...register("ssn", { minLength: {value : 11, message : " Must enter a valid SSN"}, required: "Please enter your Social Security Number" })}
                  />
                </div>
                {errors.ssn ? (
                  <div className="error-message text-center">
                    {errors.ssn.message}
               
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group">
              {errors.notfound ? (
                  <div className="error-message text-center">
                    {errors.notfound.message}
               
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group">
                <button
                  onClick={() => {utms.trackEvent(8, "DM", "", Setting.productSettings.ProductId, "Flow");PixelService.event("google", "DM", "Confirm Reservation Button");}}
                  disabled={isLoading}
                  style={{ backgroundColor: Setting.brandSettings.Tertiary, color: "#fff" }}
                  className="btn pt-2 pb-2 w-100 mt-3"
                  type="submit"
                >
                  {isLoading ? "CONFIRMING..." : "CONFIRM RESERVATION"}
                </button>
              </div>
              <div className="col-12 mt-5 pl-0">
                {
                  (features.isFeatureEnabled('ReservationLookUpPageActive') || prequal.includes(Setting.brandSettings.ProductName)) && (
                    <>
                      <h3>
                        <strong>Need Help?</strong>
                      </h3>
                      <ul className="dm-ul"> 
                        {
                          features.isFeatureEnabled('ReservationLookUpPageActive') && (
                            <li>
                              Don't have your Reservation Number?{" "}
                              <a
                                href={'/reservation' + window.location.search}
                                rel="noopener noreferrer"
                                target="_self"
                              >
                                Search Now
                              </a>
                            </li>
                          )
                        }
                        
                        {prequal.includes(Setting.brandSettings.ProductName) && (
                          <li>
                            Didn't receive an offer in the mail?{" "}
                            <a
                              href={
                                "http://prequal2.your" +
                                Setting.brandSettings.ProductName.toLowerCase() +
                                "card.com"
                              }
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              See if You Pre-Qualify
                            </a>
                          </li>
                        )}
                      </ul>
                    </>
                  )
                }
               
              </div>
            </div>
            
            <div className="col-lg-1 d-xs-none d-flex justify-content-center">
              <span className="border-left"></span>
            </div>
            <div className="col-lg-5 col-xs-6">
              <img
                src={Setting.brandSettings.CardImage}
                alt={`${Setting.brandSettings.ProductName} Card`}
                className="col-lg-12"
              />
             
                <p
                  className="col-lg-12"
                  style={{ fontSize: "small", color: "black", textAlign: "center" }}
                >
                  {Parser(Setting.brandSettings.CardImageNote || "")}
                </p>
              
              
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Landing;
