import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { masking, unmasking, IncomeMask } from "../../../../services/masking";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AxiosWrapper from "../../../../services/axios";
import { useEffect } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "../../../../assets/flows/crosssell/crosssell.css";
import PixelsService from '../../../../services/pixels';
import marketing from "../../../../services/marketing";
import AudioEyeSupport from "../../../../services/audioEyeSupport";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AdobeScript from "../../../../services/adobeScript";
import ValidatorService from "../../../../services/validator";

function Landing() {

  const ada = new AudioEyeSupport();
  const validatorService = new ValidatorService();
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const text = useStoreState((state) => state.crossSellText.landing);
  const setInfoStore = useStoreActions((actions) => actions.applicantModel.setApplicant);
  const storeApplicantData = useStoreActions((actions) => actions.applicantModel.storeApplicantData);
  const setdisplayTerms = useStoreActions((actions) => actions.setDisplayPrequalTerms);
  const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
  const history = useHistory();
  const PixelService = new PixelsService();
  const axios = new AxiosWrapper();
  const utms = new marketing();
  const { handleSubmit, register, formState: { errors }, setError, setFocus, clearErrors } = useForm({ mode: "onSubmit" });
  const [isLoading, setLoading] = useState(false);
  const [cursor, setCursorPosition] = useState(1)
  let [info, setInfo] = useState({
    reservationNumber: "",
    ssn: "",
    Email: "",
    MonthlyIncome: "",
    productName: Setting.brandSettings.ProductName,
    campaignId: axios.findParam("campaignId") || "",
  });
  const setInitPixels = useStoreActions(actions => actions.setInitPixels);
  const initPixels = useStoreState(state => state.initPixels);
  const setUseOwnBanner = useStoreActions(actions => actions.siteSettingsModel.setUseOwnBanner);
  const adobeAds = new AdobeScript()

  useEffect(() => {
    if (!initPixels) {
      utms.mapper();
      ada.setup();
      adobeAds.setup()
      utms.trackEvent(26, "CS", "", Setting.productSettings.ProductId, "Flow");
      PixelService.initialize().pageView("/landing").event("google", "tracking", "landing");
      setUseOwnBanner(true)



      setInitPixels(true)
    }
    getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (axios.findParam("cfcechash")) {
      axios.get("get-dm-reservation-by-hash/" + axios.findParam("cfcechash")).then((response) => {
        if (typeof response === "undefined") {
          return;
        }

        changeReservationNumber(response)
        return;
      }).catch((err) => {
        return;
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const submit = async (data) => {
    setLoading(true);

    try {
      const response = await axios.post("/validate-cs-reservation", {
        ...info,
        grossMonthlyIncome: info.MonthlyIncome,
      }, true);

      if (
        typeof response.Success === undefined ||
        response.Success === false ||
        String(response.Payload.ReservationCode) !== info.reservationNumber
      ) {
        setError("reservationNumber", {
          type: "custom",
          message: response.ErrorItem?.Message || "Invalid data entered",
        });
        setLoading(false);
        return;
      }

      const isEmailValid = await validatorService.EmailValidate(info.Email, { setError, setFocus });
      if (!isEmailValid) {
        setLoading(false);
        return;
      }

      const payload = response.Payload;
      payload.SSN = info.ssn;
      payload.dob = moment(payload.BirthDate, "YYYYMMDD", true).format("YYYY-MM-DD");
      payload.PrimaryPhone = payload.MobileNo || "";
      payload.PrimaryPhoneSms = payload.MobileNo || "";
      payload.SecondaryPhone = payload.HomeNo || "";
      payload.SecondaryPhoneSms = payload.HomeNo || "";
      payload.Email = info.Email;
      payload.MonthlyIncome = info.MonthlyIncome;

      const applicantData = {
        ...payload,
        MonthlyIncome: info.MonthlyIncome,
        Email: info.Email,
      };

      storeApplicantData(applicantData);
      setInfoStore({ index: "ssn", value: info.ssn });
      setdisplayTerms(true);
      window.scrollTo(0, 0);
      history.push("/terms" + window.location.search);
    } catch (err) {
      setError("reservationNumber", {
        type: "custom",
        message: "An error occurred while validating the reservation",
      });
    }

    setLoading(false);
  };

  const changeReservationNumber = newValue => {
    clearErrors("notfound")
    const eventMock = { nativeEvent: { inputType: "" }, target: { value: newValue } };
    const regExp = new RegExp("([0-9]+$|[0-9]+$|^$)");
    if (regExp.test(newValue)) {
      setInfo({ ...info, reservationNumber: unmasking(eventMock, "XXXXXXXXXXX", info.reservationNumber) });
    }
  }

  return (
    <>
      <hr style={{ borderColor: Setting.brandSettings.ProductName === "Surge" ? Setting.brandSettings.Secondary : Setting.brandSettings.Primary, width: "100%", borderWidth: "4px", marginTop: "0px" }} />
      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-9 col-md-6 col-xl-5 d-flex flex-column align-items-center">
          <div className="limit-title text-center mb-3" style={{ color: Setting.brandSettings.Primary }}>
            You’ve been pre-approved for a second Mastercard<span style={{ verticalAlign: "super", fontSize: "0.75em" }}>&reg;</span>!
          </div>
          <img src={Setting.brandSettings.CardImage} alt={`${Setting.brandSettings.ProductName} Card`} className={Setting.brandSettings.ProductName === "Revel" ? "card-image-revel" : "card-image"} />
        </div>
      </div>
      <div>
        <div className="container pt-5 pb-5">
          <form onSubmit={handleSubmit(submit)}>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-9 col-lg-6">
                <div className="form-group">
                  <label className="w-100" htmlFor="reservationNumber">
                    {text.block1.reservationNumber}
                  </label>
                  <div className="input-container">
                    <input
                      value={masking(info.reservationNumber, "XXXXXXXXXXX")}
                      onInput={(e) => {
                        const onlyNumbers = e.target.value.replace(/[^\d]/g, "");
                        e.target.value = onlyNumbers;
                        changeReservationNumber(e.target.value)
                      }}
                      type="text"
                      className="form-control text-center"
                      placeholder="XXXXXXXXXX"
                      {...register("reservationNumber", { required: "Please enter your Reservation Number.", minLength: { value: 11, message: "Invalid Reservation Code" } })}
                      onPaste={(e) => {
                        e.preventDefault();
                        const pastedText = e.clipboardData.getData("text/plain");
                        const onlyNumbers = pastedText.replace(/[^\d]/g, "");
                        const currentCursorPosition = e.target.selectionStart;
                        const newValue = e.target.value.substring(0, e.target.selectionStart) + onlyNumbers + e.target.value.substring(e.target.selectionEnd);
                        e.target.value = newValue;
                        e.target.selectionStart = e.target.selectionEnd = currentCursorPosition + onlyNumbers.length;
                        setInfo({ ...info, reservationNumber: e.target.value });
                      }}
                    />
                  </div>
                  {errors.reservationNumber && (
                    <div className="error-message text-center">
                      {errors.reservationNumber.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="w-100" htmlFor="ssn">
                    {text.block1.securityNumber}
                  </label>
                  <div className="input-container d-flex">
                    <input
                      value={masking(info.ssn, "XXX-XX-XXXX")}
                      onInput={(e) => {
                        clearErrors("notfound")
                        const regExp = /^[0-9]+$/;
                        // We need to check only numbers without dashes, the e.target.value is the masked input.
                        const valueWithoutDashes = e.target.value.replaceAll("-", "");
                        if (regExp.test(valueWithoutDashes) || valueWithoutDashes === "") {
                          setInfo({
                            ...info,
                            ssn: unmasking(e, "XXX-XX-XXXX", info.ssn),
                          });
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        const pastedText = e.clipboardData.getData("text/plain");
                        const onlyNumbers = pastedText.replace(/[^\d]/g, "");
                        const currentCursorPosition = e.target.selectionStart;
                        const newValue = e.target.value.substring(0, e.target.selectionStart) + onlyNumbers + e.target.value.substring(e.target.selectionEnd);
                        e.target.value = newValue;
                        e.target.selectionStart = e.target.selectionEnd = currentCursorPosition + onlyNumbers.length;
                        setInfo({ ...info, ssn: unmasking(e, "XXX-XX-XXXX", info.ssn) });
                      }}
                      type="text"
                      maxLength={11}
                      className="form-control text-center"
                      placeholder="XXX-XX-XXXX"
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          const regExp = new RegExp("([0-9]+$|^$)");
                          // We need to check only numbers without dashes, the e.target.value is the masked input.
                          const valueWithoutDashes = event.target.value.replaceAll("-", "");
                          if (regExp.test(valueWithoutDashes)) {
                            setInfo({
                              ...info,
                              ssn: unmasking(event, "XXX-XX-XXXX", info.ssn),
                            });
                          }
                          handleSubmit(submit)();
                        }
                      }}
                      {...register("ssn", { minLength: { value: 11, message: " Must enter a valid SSN" }, required: "Please enter your Social Security Number." })}
                    />
                    <FontAwesomeIcon icon={faLock} style={{ marginLeft: "-25px", paddingTop: "10px", color: "grey" }} />
                  </div>
                  {errors.ssn && (
                    <div className="error-message text-center">
                      {errors.ssn.message}
                    </div>
                  )}
                </div>

                {/* Email Input */}
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <div className="input-container">
                    <input
                      maxLength={100}
                      value={info.Email}
                      onInput={(e) => {
                        if (
                          /[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) ||
                          e.nativeEvent.inputType === "deleteContentBackward"
                        ) {
                          if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === "") {
                            if ((e.target.value.split("@").length - 1) < 2) {
                              setInfo({ ...info, Email: e.target.value });
                            }
                          }
                        }
                      }}
                      type="text"
                      className="form-control"
                      placeholder=""
                      {...register("Email", {
                        required: "Please enter your Email Address.",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                  </div>
                  {errors.Email && (
                    <div className="error-message text-center">{errors.Email.message}</div>
                  )}
                </div>
                {/* Monthly Income Input */}
                <div className="form-group">
                  <label htmlFor="Monthly Income">
                    {" "}
                    Your Monthly Income{" "}<br></br>
                    <span className="text-danger error-message">{info.MonthlyIncome >= 12000 ? "Please Ensure that total Monthly Income is Correct!" : ""}</span>
                  </label>
                  <div className="input-container">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip className="mytooltip" id="monthly-tooltip" >{text.block1.tooltipIncome}</Tooltip>}>
                      <input
                        value={IncomeMask(info.MonthlyIncome)}
                        onInput={(e) => {
                          e.preventDefault()
                          setCursorPosition(e.target.selectionStart)

                          let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("$", "").replaceAll(",", "") : e.nativeEvent.data;

                          if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                            if (e.target.value.replaceAll("$", "").replaceAll(",", "") <= 99999) {
                              let value = e.target.value.replaceAll("$", "").replaceAll(",", "");
                              value = (!isNaN(value)) ? parseFloat(value).toFixed(0) : value;
                              setInfo({ ...info, "MonthlyIncome": (isNaN(value) ? "" : value) })
                            }
                          }
                        }}
                        onKeyDown={(e) => {
                          setCursorPosition(e.target.selectionStart)
                        }}
                        onKeyUp={(e) => {
                          if (e.nativeEvent.key === "Backspace") {
                            e.target.setSelectionRange(cursor, cursor);
                          }
                        }}
                        type="text" className="form-control" placeholder="$ 0.00"
                        {...register("MonthlyIncome", {
                          validate: value => (!isNaN(value.replace("$", "").replace(",", ".")) && value.replace("$", "").replace(",", "") > 0) || "Monthly income must not be empty"
                        })}
                      />
                    </OverlayTrigger>
                    {errors.MonthlyIncome ?
                      <div className="error-message text-center"> Please enter a Monthly Income.<br /></div> : <></>
                    }
                  </div>
                </div>

                <div className="form-group text-center" style={{ width: "fit-content", margin: "auto" }}>
                  <button
                    onClick={() => { utms.trackEvent(27, "CS", "", Setting.productSettings.ProductId, "Flow"); PixelService.event("google", "CS", "Confirm Reservation Button"); }}
                    disabled={isLoading}
                    style={{
                      backgroundColor: Setting.brandSettings.ProductName === "Surge" ? Setting.brandSettings.Secondary : Setting.brandSettings.Primary,
                      color: Setting.brandSettings.ProductName === "Surge" ? Setting.brandSettings.Primary : "white"
                    }}
                    className="btn p-2 w-100 mt-3 text-center confirm-btn"
                    type="submit"
                  >
                    {isLoading ? "Confirming..." : "Confirm Reservation"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Landing;
