import React, { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { useForm } from "react-hook-form";
import moment from "moment";
import { masking, unmasking } from "../../../../services/masking";
import AxiosWrapper from "../../../../services/axios";
import { useHistory } from "react-router-dom";
import ErrorModal from '../../../body/forms/components/ErrorModal';
import AdobeScript from "../../../../services/adobeScript";

function DebitCard(props) {
  const setting = useStoreState((state) => state.siteSettingsModel);
  const applicant = useStoreState((state) => state.applicantModel.applicant);
  const applicationResult = useStoreState((state) => state.applicationResultModel.applicationResult);
  const { handleSubmit, register, watch, formState: { errors } } = useForm({ mode: "onSubmit" });
  const MainPath = useStoreState((state) => state.siteSettingsModel.MainPath);
  const [cursor, setCursorPosition] = useState(1);
  const axios = new AxiosWrapper();
  const adobe = new AdobeScript();
  const getYears = () => {
    let years = [];
    for (let i = 0; i < 15; i++) {
      years.push(moment().year() + i);
    }
    return years;
  };
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const closeError = () => setErrorShow(false);
  const isAuthorized = watch("authorize");
  const [card, setCard] = useState({
    cardNumber1: "",
    cardNumber2: "",
    cardNumber3: "",
    cardNumber4: "",
  });

  let [info, setInfo] = useState({
    ApplicantId: applicationResult.ref_number,
    AccountTypeId: 0,
    PaymentTypeId: 2,
    PaymentTypeMethod: "DEBIT",
    Amount: 95,
    CardNumber: "",
    CVV: "",
    BillingZipCode: "",
    ExpirationDateMonth: "",
    ExpirationDateYear: "",
    RoutingNumber: "",
    AccountNumber: "",
    UserId: "/make-payment",
    ApplicationReference: setting.productSettings.ApplicationSourceReference
  });

  const Submit = async (data) => {
    setLoading(true);
    sessionStorage.setItem("Payment", 1);

    const response = await axios.post("/make-payment", { ...info }, true);
    adobe.event('card_submit')
    if (!response || typeof response.Success === undefined || response.Success === false) {
      setLoading(false);
      setErrorShow(true);
      adobe.event('errorPayment');
      setErrorMessage(response.Error);
      return;
    }
    setLoading(false);
    window.scrollTo(0, 0);
    history.push(MainPath + "enjoy");

    return;
  };

  useEffect(() => {
    setInfo((info) => ({ ...info, CardNumber: `${card.cardNumber1}${card.cardNumber2}${card.cardNumber3}${card.cardNumber4}` }));

  }, [card]);

  const handleChangeMonth = (event) => {
    setInfo({ ...info, ExpirationDateMonth: event.target.value })
  };

  const handleChangeYear = (event) => {
    setInfo({ ...info, ExpirationDateYear: event.target.value })
  };

  return (
    <div className="col-12 p-0">
      <ErrorModal size='sm' show={errorShow} message={errorMessage} closeError={closeError} />
      <div className="noRadiusTop">
        <div
          className="no-bottom-margin  row"
          style={{ marginBottom: "0 !important" }}
        >
          <div className="col-12">
            <form onSubmit={handleSubmit(Submit)} className="w-100">
              <div className="row">
                <div className="col-12 text-left">
                  <p className="p-0 payment-description">
                    Making your payment with a Visa, Discover, or Mastercard is the quickest way to open your new account. Successful payment with a card, and activation upon the card’s arrival, will enable you to access available credit. There are no hold periods with a card payment.
                  </p>
                </div>
              </div>
              <div className="row card-box w-100" style={{ marginLeft: "inherit" }}>
                <div className="result-message">
                  <div><strong>Approved Applicant:</strong> {applicant.FirstName || "Test"} {applicant.LastName || "Test"}</div>
                  <div ><strong>Reference Number:</strong> {applicationResult.ref_number || "16212152"}</div>
                  {/* this needs to change when we include processinf fee in the backend response */}
                  <div ><strong>Processing Fee:</strong> ${applicationResult.security_deposit}</div>
                </div>
              </div>

              <div className='row'>
                <div className="col-lg-3 col-md-4 col-6 align-items-start my-2">
                  <h5>We Accept</h5>
                  <img
                    src="/credit-logos.png"
                    alt="visa mastercard discover logos"
                    style={{ width: "inherit" }}
                  />
                </div>
              </div>

              <div className="debit-card-box">
                <div className="row">
                  <div className="col-12">
                    <h3 className="mb-0">Card Number</h3>
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-12 d-flex flex-row align-items-center">
                    <div style={{ width: "22%" }}>
                      <input
                        minLength={4}
                        maxLength={4}
                        value={masking(card["cardNumber1"], "XXXX")}
                        onInput={(e) => {
                          let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                          if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                            setCard({...card, cardNumber1: unmasking(e, "XXXX", value)});
                          }
                        }} 
                        onKeyDown={(e) => {
                            setCursorPosition(e.target.selectionStart)
                        }}
                        onKeyUp={(e) => {
                            if(e.nativeEvent.key === "Backspace" ){
                                e.target.setSelectionRange(cursor, cursor);
                            }
                        }}
                        type="text"
                        className="form-control text-center"
                        {...register("cardNumber1", { required: true })}
                      />
                    </div>
                    <div style={{ width: "4%" }} className="text-center">-</div>
                    <div style={{ width: "22%" }}>
                      <input
                        minLength={4}
                        maxLength={4}
                        value={masking(card["cardNumber2"], "XXXX")}
                        onInput={(e) => {
                          let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                          if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                            setCard({...card, cardNumber2: unmasking(e, "XXXX", value)});
                          }
                        }} 
                        onKeyDown={(e) => {
                            setCursorPosition(e.target.selectionStart)
                        }}
                        onKeyUp={(e) => {
                            if(e.nativeEvent.key === "Backspace" ){
                                e.target.setSelectionRange(cursor, cursor);
                            }
                        }}
                        type="text"
                        className="form-control text-center"
                        {...register("cardNumber2", { required: true })}
                      />
                    </div>
                    <div style={{ width: "4%" }} className="text-center">-</div>
                    <div style={{ width: "22%" }}>
                      <input
                        minLength={4}
                        maxLength={4}
                        value={masking(card["cardNumber3"], "XXXX")}
                        onInput={(e) => {
                          let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                          if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                            setCard({...card, cardNumber3: unmasking(e, "XXXX", value)});
                          }
                        }} 
                        onKeyDown={(e) => {
                            setCursorPosition(e.target.selectionStart)
                        }}
                        onKeyUp={(e) => {
                            if(e.nativeEvent.key === "Backspace" ){
                                e.target.setSelectionRange(cursor, cursor);
                            }
                        }}
                        type="text"
                        className="form-control text-center"
                        {...register("cardNumber3", { required: true })}
                      />
                    </div>
                    <div style={{ width: "4%" }} className="text-center">-</div>
                    <div style={{ width: "22%" }}>
                      <input
                        minLength={4}
                        maxLength={4}
                        value={masking(card["cardNumber4"], "XXXX")}
                        onInput={(e) => {
                          let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                          if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                            setCard({...card, cardNumber4: unmasking(e, "XXXX", value)});
                          }
                        }} 
                        onKeyDown={(e) => {
                            setCursorPosition(e.target.selectionStart)
                        }}
                        onKeyUp={(e) => {
                            if(e.nativeEvent.key === "Backspace" ){
                                e.target.setSelectionRange(cursor, cursor);
                            }
                        }}
                        type="text"
                        className="form-control text-center"
                        {...register("cardNumber4", { required: true })}
                      />
                    </div>
                  </div>
                  {(errors.cardNumber1 || errors.cardNumber2 || errors.cardNumber3 || errors.cardNumber4)
                    ? (
                      <p className="error-message">
                        This field is missing or invalid. Please fill it correctly.
                      </p>
                    )
                    : ""}
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 marginMD">
                    <div className="row">
                      <div className="col-12">
                        <h3>Expiration Date</h3>
                      </div>
                      <div className="col-6">
                        <div className="input-container">
                          <select
                            className="form-control"
                            id="ExpirationDateMonth"
                            {...register("ExpirationDateMonth", {
                              onChange: handleChangeMonth,
                              required: "Please enter the expiration month",
                              validate: {
                                emptyOrNull: value =>
                                  (value.trim() !== "" && value.trim() !== "NULL") || "Expiration month must not be empty or NULL"
                              }
                            })}
                          >
                            <option value="NULL" selected>Month</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                        </div>
                        {errors.ExpirationDateMonth
                          ? errors.ExpirationDateMonth && (
                            <p className="error-message">
                              This field is missing or invalid. Please fill it correctly.
                            </p>
                          )
                          : ""}
                      </div>
                      <div className="col-6">
                        <div className="input-container">
                          <select
                            className="form-control"
                            id="ExpirationDateYear"
                            {...register("ExpirationDateYear", {
                              onChange: handleChangeYear,
                              required: "Please enter the expiration year",
                              validate: {
                                emptyOrNull: value =>
                                  (value.trim() !== "" && value.trim() !== "NULL") || "Expiration year must not be empty or NULL"
                              }
                            })}
                          >
                            <option value="NULL" selected>Year</option>
                            {getYears().map((year) => {
                              return <option value={year}>{year}</option>;
                            })}
                          </select>
                        </div>
                        {errors.ExpirationDateYear
                          ? errors.ExpirationDateYear && (
                            <p className="error-message">
                              This field is missing or invalid. Please fill it correctly.
                            </p>
                          )
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 marginMD">
                    <div className="row">
                      <div className="col-12">
                        <h3>Security Code</h3>
                      </div>
                      <div className="col-6 col-sm-12 cvvDiv">
                        <input
                          value={info.CVV}
                          maxLength={3}
                          onInput={(e) => {
                            const onlyNumbers = e.target.value.replace(/\D/g, "");
                            setInfo({ ...info, CVV: onlyNumbers });
                          }}
                          type="text"
                          className="form-control"
                          placeholder="cvv"
                          {...register("CVV", {
                            valueAsNumber: true,
                            validate: (value) => value > 0,
                          })}
                        />
                        {errors.CVV
                          ? errors.CVV && (
                            <p className="error-message">
                              This field is missing or invalid. Please fill it correctly.
                            </p>
                          )
                          : ""}
                        <img
                          src="/sec-code-icon.png"
                          alt="security code"
                          className='align-self-end cvvImg d-none d-sm-block'
                        />
                      </div>
                      <div className="col-6 d-block d-sm-none text-right">
                        <div className="d-flex justify-content-end align-items-center">
                          <img
                            src="/sec-code-icon.png"
                            alt="security code"
                            className='cvvImg'
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                    <h3>Billing Zip Code</h3>
                    <input
                      value={info.BillingZipCode}
                      maxLength={9}
                      onInput={(e) => {
                        let value =
                          typeof e.nativeEvent.data === "undefined" ||
                            e.nativeEvent.data === null
                            ? e.target.value
                            : e.nativeEvent.data;
                        if (
                          /[0-9]/.test(value) ||
                          e.nativeEvent.inputType ===
                          "deleteContentBackward"
                        ) {
                          if (
                            /^[0-9]+$/.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setInfo({ ...info, BillingZipCode: e.target.value });
                          }
                        }
                      }}
                      type="text"
                      className="form-control"
                      {...register("BillingZipCode", { required: true })}
                    />
                    {errors.BillingZipCode
                      ? errors.BillingZipCode && (
                        <p className="error-message">
                          This field is missing or invalid. Please fill it correctly.
                        </p>
                      )
                      : ""}
                  </div>
                </div>
                <div className="row mb-0">
                  <div className="col-12">
                    <div className="form-check check-fields">
                      <div className="input-container">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="authorize"
                          {...register("authorize", { required: true })}
                        />
                        <label className="form-check-label pt-0" htmlFor="authorize">
                          By checking this box, I sign and agree to authorize{" "}
                          {setting.brandSettings.BankName} to initiate this
                          debit or prepaid transaction from the account listed
                          above in the amount specified.
                        </label>
                      </div>
                      {errors.authorize
                        ? errors.authorize && (
                          <span className="error-message">
                            You must acknowledge before continuing.
                          </span>
                        )
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-0 mt-1 justify-content-center">
                <div className="col-12 d-flex">
                  {errors.request
                    ? errors.request && (
                      <span
                        className="error-message mt-2 mb-3"
                        style={{ display: "block" }}
                      >
                        An error has occurred. And your payment was declined
                        please check your card information and try agaian.
                      </span>
                    )
                    : ""}
                  <button
                    variant="primary"
                    className="pay-processing-button"
                    type="submit"
                    disabled={isLoading || !isAuthorized}
                    style={{ backgroundColor: setting.brandSettings.BannerBgColor, border: "1px solid transparent", opacity: (isLoading || !isAuthorized) ? 0.5 : 1 }}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebitCard;
