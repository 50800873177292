import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import AxiosWrapper from "../../../services/axios";
import Wildcard from "../../../services/wildcard";
import marketing from "../../../services/marketing";
import PixelsService from "../../../services/pixels";

function ColorCardChoiceForm() {

    const setting = useStoreState((state) => state.siteSettingsModel);
    const initCardColors = useStoreState((state) => state.initCardColors);
    const setInitCardColors = useStoreActions(action => action.setInitCardColors);
    const axios = new AxiosWrapper();
    const wildcard = new Wildcard();
    const dmLabels = useStoreState((state) => state.dmText.terms);
    const cardColors = useStoreState(state => state.siteSettingsModel.CardColors);
    const setCardColors = useStoreActions(action => action.siteSettingsModel.setCardColor);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const PixelService = new PixelsService();
    const applicantData = useStoreState(state => state.applicantModel.applicant);
    const setApplicantData = useStoreActions(actions => actions.applicantModel.setApplicant);
    const colFormat = (setting.brandSettings.ProductName === "Revel") ? "col-sm-6 col-xs-6 col-6 col-lg-2 col-md-4 " : "col-6";
    const textCenter = (setting.brandSettings.ProductName === "Revel") ? "text-center" : "";
    const minHeight = (setting.brandSettings.ProductName === "Revel") ? {minHeight : "5vh"} : {};
    const [showFee, setShowFee] = useState(false);
    const WaterfallValues = {"DM" : 12, "STANDALONE" : 25 , "CS" : 31}
    const utms = new marketing();

    //get cards colors
    useEffect(() => {
        if(!initCardColors) {

            let product_code = setting.mainSettings.ProductCode
         
            switch(setting.mainSettings.Flow){
                case 'PREQUAL':
                    product_code = applicationResult.product_code;
                break;
                case 'DM':
                    product_code = applicantData.ProductCode;
                break;
                case 'CS':
                    product_code = applicantData.ProductCode;
                break;
                default:
                    product_code = setting.mainSettings.ProductCode;
                break;
            }


            axios.post("get-card-color-choice/", 
            { productId: setting.productSettings.ProductId, productCode : product_code }).then((payload) => {
                if (payload) {
                    if (payload.length > 0) {
                        setCardColors(payload);
                        //preselect green
                        setApplicantData({ index: "cardColorCode", value: payload[0].Code });

                        setShowFee((payload.find((card => card.Fee > 0))) ? true : false)
                        setInitCardColors(true)
                    }
                }
            });
        };
        
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);   

    return (
        <div className="col-12 p-0">
            <div id="accept-card-anchor"></div>
            {cardColors.length > 0 ?
                <div className="row mt-2 mb-2">
                    <div className='col-12 text-center' style={{ backgroundColor: "black", padding: "1% 2%" }}>
                        <h1 className='mb-0' style={{ color: "white" }}>{wildcard.proccess(dmLabels.block2.title)}</h1>
                    </div>
                    {cardColors.map((card) => {
                        return (
                            <div className={colFormat+' p-3 mb-3'} key={"cardRow-" + card.Code}>
                                <div className='row' style={minHeight}>
                                    <div className={textCenter+" col-12"} style={{ paddingLeft: (setting.brandSettings.ProductName === "Revel") ? "0" : "16%" }}>
                                        <div className="form-check">
                                            <input
                                                onClick={() => {
                                                    PixelService.event("google", setting.mainSettings.Flow, "Card Color")
                                                    PixelService.event("google", setting.mainSettings.Flow, "Card Color " + card.DisplayName.replace("- Premium", ""))
                                                    if(setting.mainSettings.Flow === "DM" || setting.mainSettings.Flow === "STANDALONE" || setting.mainSettings.Flow === "CS"){ 
                                                        utms.trackEvent(
                                                            WaterfallValues[setting.mainSettings.Flow], 
                                                            setting.mainSettings.Flow, 
                                                            applicationResult.ref_number || "",
                                                            setting.productSettings.ProductId, 
                                                            "Flow");}
                                                    }
                                                }
                                                checked={applicantData.cardColorCode === card.Code ? true : false}
                                                onChange={() => { setApplicantData({ index: "cardColorCode", value: card.Code }) }}
                                                className="form-check-input"
                                                type="radio"
                                                value={card.Code}
                                                name="CardColorCode"
                                                id={"cardRadio-" + card.Code}
                                            />
                                            <label className="form-check-label color-prices pt-0" htmlFor={"cardRadio-" + card.Code} style={{}}>
                                                {card.DisplayName.replace("- Premium", "")}
                                                {
                                                    parseFloat(card.Fee) < 0.01 ? <>  {showFee ?<> : <strong>No Fee</strong> </>: <></>}  </> : <> : <strong>${card.Fee.toFixed(2)}</strong></>
                                                }
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row text-center mt-3'>
                                    <img
                                        alt="" src={card.ColorCardFileName}
                                        style={{ width: "70%", textAlign: "center", margin: "auto" }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            : <></>}                    
        </div>        
    );
}

export default ColorCardChoiceForm;
