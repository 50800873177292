import { action, thunk } from 'easy-peasy';
import AxiosWrapper from '../services/axios';

export default {
    //State
    authorization : "-",
    domain : "https://"+window.location.hostname,
    mainSettings: {
        BrandSettingsId: "",
        DateCreated: "",
        EngineId: "",
        Flow: "",
        HelperEngine: "",
        HelperMatrix: "",
        Id: "",
        Matrix: "",
        Message: "",
        ProductCode: "",
        ProductSettingsId: "",
        SettingsId: "",
        Status: "",
        Url: "",
        IsActive : true,
        AudioEye : null,
        RedirectTo : null
    },
    productSettings: {
        AppRef: "",
        ProductId: "",
        PrivacyUrl: "",
        FaqUrl: "",
        TermsUrl: "",
        OmpsUrl: "",
        ESignUrl: "",
        TrustpilotUrl: "",
        OnlineCreditProtectionUrl: "",
        Features: [],
        BannerText: { Title: "", SubTitle: "", Description: [], Note: "" }
    },
    brandSettings: {
        ProductName: "",
        Primary: "",
        Secondary: "",
        Tertiary: "",
        Quarternary: "",
        CardImage: "",
        HeaderLogo: "",
        FooterLogo: "",
        BannerImage: "",
        BannerBgColor: "",
        FooterBgColor: "",
        CardImageNote: "",
        CustomerServiceNumber: "",
        BankName: ""
    },
    otherCardSettings: {
        AbandonFlag: null,
        AbandonURLRedirect: "",
        DeclineFlag: null,
        DeclineURLRedirect: "",
        ICommDeclineFlag: null,
        ICommDeclineOtherOfferUrl: "",
        ICommDeclineOtherOfferLclNoCheckingUrl: "",
        OtherOfferUrl: ""
    },
    webAssetsBaseUrlSettings: {
        StaticSite: "",
        Cdn: "",
        CdnImage: "",
        Continental: ""
    },
    websiteSettings : [],
    prefillData: [],
    validationRules: [],
    formFields: [],
    Pixels : {
     
    },
    isLoadingSettings: true,
    activateChecks: false,
    statesList: [],
    Session: "",
    CardColors : [],
    idList : "",
    pubCode : "",
    useRecaptcha : false,
    showOnDecline : true,
    invalidState : false,
    grandBankDeclined : false,
    showHeader : true,
    MainPath : "/",
    PathBasedSite : false,
    smsConcentModulePrequal: true,
    smsConcentModuleDM: true,
    smsConcentModuleCFC: true,
    smsConcentModuleCS: true,
    smsConcentModuleStandAlone: true,
    cookieConsent: null,
    cookieConsentLoaded: false,
    useOwnBanner: false,
    DeclineOffers: null,

    //Actions
    setShowHeader:                  action((state, payload) => { state.showHeader = payload }),
    setCookieConsent:               action((state, payload) => { state.cookieConsent = payload }),
    setCookieConsentLoaded:         action((state, payload) => { state.cookieConsentLoaded = payload }),
    setPathBasedSite:               action((state, payload) => { state.PathBasedSite = payload }),
    setDomain:                      action((state, payload) => { state.domain = payload }),
    setIsLoadingSettings:           action((state, payload) => { state.isLoadingSettings = payload }),
    setWebsiteSettings:             action((state, payload) => { state.websiteSettings = {...state.websiteSettings, ...payload};} ),
    overwriteWebsiteSettings:       action((state, payload) => { state.websiteSettings = payload; console.log(payload) }),
    setMainSettings:                action((state, payload) => { state.mainSettings = {...state.mainSettings, ...payload} }),
    setValidationRules:             action((state, payload) => { state.validationRules = payload }),
    setPrefillData    :             action((state, payload) => { state.prefillData = payload }),
    setProductSettings:             action((state, payload) => { state.productSettings = payload }),
    setBrandSettings:               action((state, payload) => { state.brandSettings = payload }),
    setStatesList:                  action((state, payload) => { state.statesList = payload }),
    setPixels:                      action((state, payload) => { state.Pixels = payload }),
    setAuthorization:               action((state, payload) => { state.authorization = payload }),
    setSession:                     action((state, payload) => { state.Session = payload }),
    setAppRef:                      action((state, payload) => { state.productSettings.AppRef = payload }),
    setCardColor:                   action((state, payload) => { state.CardColors = payload }),
    setOtherCardSettings:           action((state, payload) => { state.otherCardSettings = payload }),
    setWebAssetsBaseUrlSettings:    action((state, payload) => { state.webAssetsBaseUrlSettings = payload }),
    setUseRecaptcha:                action((state, payload) => { state.useRecaptcha = payload }),
    setIdList:                      action((state, payload) => { state.idList = payload }),
    setPubCode:                     action((state, payload) => { state.pubCode = payload }),
    setShowOnDeclined:              action((state, payload) => { state.showOnDecline = payload }),
    setInvalidState:                action((state, payload) => { state.invalidState = payload }),
    setGrandBanckDeclined:          action((state, payload) => { state.grandBankDeclined = payload }),
    setFormFields:                  action((state, payload) => { state.formFields = payload }),
    setMainPath:                    action((state, payload) => { state.MainPath = payload }),
    setUseOwnBanner:                action((state, payload) => { state.useOwnBanner = payload }),
    setDeclineOffers:               action((state, payload) => { state.DeclineOffers = payload }),
    
    setCreditProtectionUrl:         action((state, payload) => {
        let originalUrl = state.productSettings.OnlineCreditProtectionUrl;

        switch (true) {
            case originalUrl.includes("Surge.pdf"):
              state.productSettings.OnlineCreditProtectionUrl = originalUrl.replace("Surge.pdf", "Surge099.pdf");
              break;
            case originalUrl.includes("Cerulean.pdf"):
              state.productSettings.OnlineCreditProtectionUrl = originalUrl.replace("Cerulean.pdf", "Cerulean099.pdf");
              break;
            case originalUrl.includes("Verve.pdf"):
              state.productSettings.OnlineCreditProtectionUrl = originalUrl.replace("Verve.pdf", "Verve099.pdf");
              break;
            case originalUrl.includes("Reflex.pdf"):
              state.productSettings.OnlineCreditProtectionUrl = originalUrl.replace("Reflex.pdf", "Reflex099.pdf");
              break;
            case originalUrl.includes("Revel.pdf"):
              state.productSettings.OnlineCreditProtectionUrl = originalUrl.replace("Revel.pdf", "Revel099.pdf");
              break;
            case originalUrl.includes("Fit.pdf"):
              state.productSettings.OnlineCreditProtectionUrl = originalUrl.replace("Fit.pdf", "Fit099.pdf");
              break;
            default:
                state.productSettings.OnlineCreditProtectionUrl = originalUrl;
            }
    }),    
    
    //Primary Conf
    getSiteSettings: thunk(async (actions, payload) => {
        const api = new AxiosWrapper();
        let temporaryValue = (window.location.host.includes("cfc1") || window.location.host.includes("yourfitcard")) ? true : false;
        let sid = (!api.findParam("sid")) ? "" : api.findParam("sid");
        let c1 = (!api.findParam("c1")) ? "" : api.findParam("c1");
        let c3 =(!api.findParam("c3")) ? "" : api.findParam("c3");
        let pubId = (api.findParam("pub")) ? api.findParam("pub") : api.findParam("pubid") || ""

        if (temporaryValue && (pubId==='450010' || pubId==='450065')){
  
            if (sid==='55' || (sid==='71' && c1==='5637_20789')) { c3='2'; } //exp
            if (sid==='150' || (sid==='71' && c1==='5637_20679')) { c3='1'; } //tu
        }

       
        let domain = "https://" + window.location.host + ((window.location.pathname.split("/")[1] === '') ? '' : "/" + window.location.pathname.split("/")[1]) ;
        //domain = "https://devprequal3.yourreflexcard.com"; // hardcode url for testing ex: "https://newdmtest-yourceruleancard.contfinco.net/"
        let found = 1;
        let data = await api.post('get-configuration', { 
            url: domain, 
            environment: process.env.REACT_APP_CFC_APPLICANT_BUILDENV, 
            form: "home", 
            pubId: pubId || "", 
            token: api.findParam("token") || "", 
            C3:c3,
            ExternalPrefillReference : api.findParam('extPreRef') || ""
        }, true)

        if(!data || data.Success === false){ 
            domain = "https://" + window.location.host;
            found = 2;
            data = await api.post('get-configuration', { url: domain, environment: process.env.REACT_APP_CFC_APPLICANT_BUILDENV, form: "home", pubId: pubId, token: api.findParam("token") || "", C3: c3, ExternalPrefillReference : api.findParam('extPreRef') || "" },true);
         }

        if(!data || data.Success === false){ return false }
        if(found === 2 && window.location.pathname.split("/")[1] !== '' && window.location.pathname.split("/")[1] !== 'reservation' && window.location.pathname.split("/")[1] !== 'offer' && data.Payload.MainSettings.Flow === "STANDALONE"){ return false }

        actions.setDomain(domain);
        data = data.Payload;

        if(found === 1 && window.location.pathname.split("/")[1] !== ''){
            actions.setPathBasedSite(true);
        }

        if(typeof data.MainSettings.RedirectTo !== "undefined" && data.MainSettings.RedirectTo !== null){ window.location.replace(data.MainSettings.RedirectTo + window.location.search); return "redirection"; }

        sessionStorage.setItem("sessionToken", data.Token);
        localStorage.setItem("sessionToken", data.Token);
        sessionStorage.setItem("oldSessionToken", data.Session);
        actions.setCookieConsent(localStorage.getItem("userActiveConsent"))
        sessionStorage.setItem("defaultTrafficSource", (api.findParam("pub") || api.findParam("pubid"))? data.ProductSettings.TrafficSource : "Organic");
        sessionStorage.setItem("ProductId", data.ProductSettings.ProductId || "");
        sessionStorage.setItem("ReportId", data.SessionReportId || "0");
        //data.MainSettings.ProductCode = "0352";
        data.MainSettings.Flow = api.findParam("useFlow") || data.MainSettings.Flow;
        data.MainSettings = { ...data.MainSettings, ...{ c3 }};
        actions.setMainSettings(data.MainSettings);
       
        actions.setBrandSettings(data.BrandSettings);
        actions.setProductSettings(data.ProductSettings);
        actions.setAppRef(data.ProductSettings.ApplicationSourceReference);
        actions.setValidationRules(data.ValidationRules || []);
        if(typeof data.FormFields == "undefined"){data.FormFields = []}
      
        actions.setUseRecaptcha(data.FormFields.find(field => field.fieldName.toUpperCase() === "CAPTCHA" && field.isActive === true) || false);
        actions.setFormFields(data.FormFields || []);
        actions.setStatesList(data.StatesList || []);
        actions.setPrefillData(data.PrefillData || []);

        let features = data.WebsiteSettings || [];
        features = features.filter(feature => feature.Value === 'true');
        actions.setWebsiteSettings(features)
        data.Pixels = data.Pixels || [];
        actions.setPixels(data.Pixels || []);
        actions.setSession(data.Session);
        actions.setIsLoadingSettings(false);
        actions.setOtherCardSettings(data.OtherCardSettings);
        actions.setWebAssetsBaseUrlSettings(data.WebAssetsBaseUrlSettings);
        return data;
    })
}