import React from 'react';
import { useStoreState } from "easy-peasy";
import AxiosWrapper from './axios';

class AdobeScript extends React.Component {

    constructor() {
        super();
        this.Setting    = useStoreState((state) => state.siteSettingsModel);
        this.Applicant  = useStoreState((state) => state.applicationResultModel.applicationResult);
        this.axios = new AxiosWrapper();
    }

    setup = () => {
        let adobeScript = document.createElement('script');
       
        adobeScript.setAttribute('src',process.env.REACT_APP_ADOBE_URL);
        adobeScript.setAttribute('async', "");
        document.head.appendChild(adobeScript);
        console.log("Adobe Service Loaded!")
        return this
    }

    startEvent = (event) => {
        if(typeof window.adobeDataLayer !== 'undefined' ){
               
            window.adobeDataLayer.push({
                "event": event,
                campaign: {
                    utm_channel:            this.axios.findParam('utm_channel') || undefined,
                    utm_medium:             this.axios.findParam('utm_medium') || undefined,
                    utm_name:               this.axios.findParam('utm_name') || undefined,
                    utm_source:             this.axios.findParam('utm_source') || undefined,
                    utm_source_platform:    this.axios.findParam('utm_source_platform') || undefined,
                    utm_strategy:           this.axios.findParam('utm_strategy') || undefined,
                },
                cardType : this.Setting.brandSettings.ProductName,
                pageName: 'cf | '+this.Setting.brandSettings.ProductName+' | application | '+this.Setting.mainSettings.Flow+'',
                pageCleanUrl: window.location.origin + window.location.pathname,
                pageHostname: window.location.host,
                pageQuerystring: window.location.search === "" ? undefined : window.location.search,
                pageCategory: 'cf | '+this.Setting.brandSettings.ProductName+' | application',
                pageSubcategory: undefined,
                siteSection: 'cf | '+this.Setting.brandSettings.ProductName+' | application',
                pageType: 'application',
                userState: 'view',
                userType: 'viewer'
            })
        }
    }

    event = (eventName, cardColor = '') => {
        if(typeof window.adobeDataLayer !== 'undefined' ){
            
            let event = { "event": eventName }
            
            if(eventName === 'pendinfo')           { event = {...event,...{applicant_id : this.Applicant.ref_number || '', cardType : this.Setting.brandSettings.ProductName}}}
            if(eventName === 'PayFee')             { event = {...event,...{applicant_id : this.Applicant.ref_number || '', cardType : this.Setting.brandSettings.ProductName}}}
            if(eventName === 'ApprovedPendfee')    { event = {...event,...{applicant_id : this.Applicant.ref_number || '', cardType : this.Setting.brandSettings.ProductName}}}
            if(eventName === 'form_start')         { event = {...event,...{cardType : this.Setting.brandSettings.ProductName}}}
            if(eventName === 'scroll')             { event = {...event,...{scrollMilestone : '50%'}}}
            if(eventName === 'prequal_offer')      { event = {...event,...{applicant_id : this.Applicant.ref_number || '', cardType : this.Setting.brandSettings.ProductName, creditLimit : this.Applicant.credit_limit || ''}}}
            if(eventName === 'prequal_accept')     { event = {...event,...{applicant_id : this.Applicant.ref_number || '', chosen_color : cardColor.DisplayName || undefined, cardType : this.Setting.brandSettings.ProductName, creditLimit : this.Applicant.credit_limit || ''}}}
            if(eventName === 'apply_now_click')    { event = {...event,...{chosen_color : cardColor.DisplayName || undefined, cardType : this.Setting.brandSettings.ProductName}}}
            if(eventName === 'see_my_card_offers') { event = {...event,...{ cardType : this.Setting.brandSettings.ProductName}}}
            if(eventName === 'card_submit') { event = {...event,...{ cardType : this.Setting.brandSettings.ProductName, applicant_id : this.Applicant.ref_number}}}
            if(eventName === 'bankAccount_submit') { event = {...event,...{ cardType : this.Setting.brandSettings.ProductName, applicant_id : this.Applicant.ref_number}}}
            if(['checkMoney_select', 'moneyGram_select', 'wUnion_select', 'phonePay_select', 'errorPayment', 'errorPayment'].includes(eventName)){
                if(this.Applicant.ref_number !== ''){
                    event = {...event,...{ applicant_id : this.Applicant.ref_number || '', cardType : this.Setting.brandSettings.ProductName }}
                }
            }
            if(eventName === 'approved' || eventName === 'declined' || eventName === 'no_offer'){
                if(this.Applicant.ref_number !== ''){
                    event = {...event,...{ applicant_id : this.Applicant.ref_number || '', cardType : this.Setting.brandSettings.ProductName }}
                }
            }
            if( eventName === 'declined' || eventName === 'pendinfo' || eventName === 'no_offer'){
                if(this.Applicant.ref_number !== ''){
                    event = {...event,...{ reason : this.Applicant.reason || 'undefined'}}
                }
            }

            window.adobeDataLayer.push(event)
        }
    }


}

export default AdobeScript