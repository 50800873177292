import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import marketing from '../../../services/marketing';
import OtherOptions from './otherOptions'
import GrandBankAdd from './grandbank/grandBankEntryPoint2';
import PixelsService from '../../../services/pixels';
import Parser from 'react-html-parser';
import AudioEyeSupport from '../../../services/audioEyeSupport';
import AdobeScript from '../../../services/adobeScript';
import { useHistory } from "react-router-dom";

function Declined() {    

    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const setting = useStoreState(state => state.siteSettingsModel);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const showOnDeclined = useStoreState(state => state.siteSettingsModel.showOnDeclined);
    const webAssetsBaseUrlSettings = useStoreState(state => state.siteSettingsModel.webAssetsBaseUrlSettings);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const alResult = useStoreState(state => state.alResult);
    const setAlResult = useStoreActions(actions => actions.setAlResult);
    const text = useStoreState(state => state.DeclinedText);
    const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
    const utms = new marketing()
    const history = useHistory();
    const ada = new AudioEyeSupport();
    const url = "https://" + ((process.env.REACT_APP_CFC_APPLICANT_BUILDENV !== "PRODUCTION") ? "test" : "") +"offer.your"+brandSettings.ProductName.toLowerCase()+"card.com/payment/?RefNo="+applicationResult.ref_number
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
    const PixelService = new PixelsService();
    const setShowHeader = useStoreActions(action => action.siteSettingsModel.setShowHeader);
    const WaterfallEvents = { "DM" : 9, "PREQUAL" : 4, "CFC" : 16, "STANDALONE" : 22, "CS" : 28 };
    const adobe = new AdobeScript();
 
    useEffect(() => {
        setShowHeader(true)
        if(alResult === false){
            //getGACode(Pixels);
            PixelService.pageView("/declined").event("google", Flow, "declined");
            utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, applicationResult.ref_number, productSettings.ProductId, "Flow");
            ada.setDYContext( { type: "OTHER" , data:["Declined", applicationResult.ref_number ]});
            ada.sendDYEvent({name : 'Declined'})
            if(typeof window.nid === 'function') {
                window.nid('closeSession')
            }
            getRoutingSettings({ Url: setting.domain, Route: "/declined"});
            setAlResult(true)
            adobe.event(Flow === 'PREQUAL' ? 'no_offer' : 'declined')
        }
       // eslint-disable-next-line  react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                history.go(1);
            }
        };
    }, [history]);

    function getNoReplyEmail(){
        if(productSettings.NoReplyEmail){
            return productSettings.NoReplyEmail
        }
        if(brandSettings.ProductName === "Revel"){
            return "noreply@revelcard.com";
        }else{
            return "noreply@your"+brandSettings.ProductName.toLowerCase()+"card.com";
        }
    }
    
    function getDeclinedMessage() {
        if(applicationResult.decline_message === "" || applicationResult.decline_message === null){
            if(text.defaultText.flags.find(Text => Text.flag === applicationResult.result.toUpperCase())){
                return text.defaultText.flags.find(Text => Text.flag === applicationResult.result.toUpperCase())["text"];
            }else{
                return text.defaultText.flags.find(Text => Text.flag === "")["text"];
            } 
        }
        let temporal = applicationResult.decline_message;
         // eslint-disable-next-line no-script-url  
        temporal = temporal.replace("javascript:void(0);", url);

        return temporal;
    }

    return (
        <div className="resultpage">
            <div className="ribbon" style={{ backgroundColor: brandSettings.Primary }}>
                <h1>{(setting.mainSettings.Flow === "PREQUAL") ? "Your Pre-qualification Results Are..." : "Thank you for applying..." }</h1>
            </div>
        <div className="container">
            {
                // eslint-disable-next-line  no-whitespace-before-property
                applicationResult.ref_number !== "" && applicationResult.ref_number !== null ?
                <div className="row" >
                    <div className="col-lg-12 text-center">
                        <h4 style={{"color" : "#f56928"}}>Reference #: {applicationResult.ref_number}</h4>
                    </div>
                </div>
                : 
                <></>
            }
           
            <div className='row'>
                <div className='card gbCard'>
                    <div className='card-body no-bottom-margin  row' style={{"marginBottom" : "0 !important"}}>
                        <div className={brandSettings.ProductName === "Revel" ? "col-lg-4 text-center" : "col-lg-4"}><img style={{width :(brandSettings.ProductName === "Revel" ? "50%" : "")}} className="col-lg-12" src={(brandSettings.ProductName === "Surge") ? ((Flow === "DM") ? webAssetsBaseUrlSettings.CdnImage+"Surge/all-colors.png" : webAssetsBaseUrlSettings.CdnImage+"Surge/card-silver.png") : brandSettings.CardImage} alt={brandSettings.ProductName} /></div>
                        <div className="col-lg-8 pt-0 result-message declined">
                            <p className='text-justify'>
                              <strong>Thank you for your interest in {brandSettings.ProductName.toLowerCase() === 'fit' ? `${brandSettings.ProductName.toUpperCase()}` : `${brandSettings.ProductName}`} Mastercard. At this time, a credit offer is not available for the following reason(s):</strong>
                            </p>
                            <p className="decline-reason gbresponse text-center">
                                { Parser(getDeclinedMessage()) }
                            </p>
                            {
                                !showOnDeclined ?
                                <p className='text-justify mb-0'>
                                    <strong>
                                            Please check your email for a message from <span style={{ color: "#008cba" }}><u>{ getNoReplyEmail() }</u></span>. You
                                            may need to review your junk e-mail for this message.
                                    </strong>
                                </p>
                                :
                                <></>
                            }
                            
                        </div>
                    </div>
                </div>
                
            </div>
           
           {(setting.grandBankDeclined === true || (setting.DeclineOffers !== null && setting.DeclineOffers.some(offer => offer.Name === "BMGDeclineOffer"))) &&
                <div className="row">
                    <div className="ribbon col-lg-12 mb-0" style={{ backgroundColor: brandSettings.Primary, borderRadius: "12px" }}>
                        {
                            (setting.grandBankDeclined !== true) ?
                            <h1>There is another option available</h1>
                            :
                            <h1>We have another option for you:</h1>
                        }
                        
                    </div> 
                </div>
            }
           
            
            {
                (setting.grandBankDeclined === true && setting.DeclineOffers !== null && setting.DeclineOffers.some(offer => offer.Name === "GBOffer")) ? <GrandBankAdd /> : <></>
            }
            
            {(setting.DeclineOffers !== null && setting.DeclineOffers.some(offer => offer.Name === "BMGDeclineOffer")) && <OtherOptions />}
            
            <div className="row text-center">
                <div className="col-lg-12 reminder" style={{textAlign: "left", border: "1px solid #c9cdd5", boxShadow : "rgb(0 0 0 / 30%) 4px 4px"}}>
                    <small style={{lineHeight: "normal", textDecoration: "underline"}}>
                        The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race, color, religion,
                        national origin, sex, marital status, age (provided that the applicant has the capacity to enter into a binding contract);
                        because all or part of the applicant's income is derived from a public assistance program; or because the applicant has in good faith
                        exercised his/her right under the Consumer Credit Protection Act. The federal agency that administers the compliance with this law concerning
                        this creditor is the Division of Depositor and Consumer Protection, National Center for Consumer and Depositor Assistance, Federal Deposit Insurance Corporation,
                        1100 Walnut Street, Box #11, Kansas City, MO 64106.
                    </small>
                </div>
            </div>
        </div>      
    </div>
    );
}

export default Declined;